<div class="mx-4 font-bold hover:text-red-400">
  <a routerLink='/blog-index'>&#8592; All Posts</a>
</div>
<div class="flex flex-col items-center mt-4 gap-y-4">
  <div class="italic font-extrabold font-sans text-3xl">
    {{post.title}}
  </div>
  <div class="text-sm text-gray-600">
    {{formatDate(post.date)}}
  </div>
  <div class="w-3/4 max-w-lg">
    {{post.body}}
  </div>
</div>

