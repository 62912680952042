<div class="flex flex-col items-center">
  <div class="italic font-extrabold font-sans text-3xl">
    All My Posts
  </div>
  <div class="mt-4 w-3/4 grid grid-cols-1 place-content-between sm:grid-cols-2">
    <div *ngFor='let post of posts' class="p-4 sm:p-8">
      <div class="flex flex-col space-y-1">
        <div>
          <span class="font-serif">{{formatDate(post.date)}}</span> <span class="italic"> by </span> <span>{{post.author}}</span>
        </div>
        <div class="italic font-bold font-sans text-xl">
          <a class="hover:text-red-400" [routerLink]="['/blog', post._id]">{{post.title}}</a>
        </div>
        <div>
          {{truncatedBody(post)}} <br>
          <a class='text-red-400 hover:text-gray-500' [routerLink]="['/blog', post._id]">Read More</a>
        </div>
      </div>
    </div>
  </div>
</div>
