<div class="flex flex-col justify-evenly items-center">
  <div class="font-mono font-bold my-4">
    Berkeley CA
  </div>
  <div class="hidden sm:flex">
    <img src="{{largePrintUrl}}">
  </div>
  <div class="sm:bg-black p-4 flex flex-col items-center space-y-6 sm:space-y-0 sm:space-x-4 sm:flex-row sm:items-center sm:justify-evenly">
    <a *ngFor='let photoUrl of listOfPhotoUrls'>
      <img (click)='setLargePrint(photoUrl)' src="{{photoUrl}}">
    </a>
  </div>
</div>